import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import React from 'react'
import { getBuildIcon } from '../utils'

const classes = {
  root: {
    minWidth: 400
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  titleText: {
    fontFamily: 'Klavika',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'inline',
    verticalAlign: 'middle'
  },
  titleIcon: {
    transform: 'scale(0.6)',
    display: 'inline',
    verticalAlign: 'middle'
  },
  envText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 18,
    display: 'inline',
    verticalAlign: 'middle'
  },
  envIcon: {
    transform: 'scale(0.4)',
    display: 'inline',
    verticalAlign: 'middle'
  },
  desc: {
    fontFamily: 'Proxima Nova',
    fontSize: 24
  },
  pos: {
    marginBottom: 12
  },
  subHeader: {
    marginLeft: '40px'
  },
  statusBox: {
    borderStyle: 'solid',
    borderColor: '#e0e1dd',
    display: 'inline',
    marginTop: 0,
    marginRight: 10,
    marginBottom: 0,
    marginLeft: 0,
    padding: '10px',
    borderWidth: 0.5,
    borderRadius: 10
  }
}

export default function BuildStatusCard(props) {
  return (
    <Grid item xs>
      <Card sx={classes.root}>
        <CardHeader
          title={
            <div>
              {getBuildIcon(props.data.status, classes.titleIcon)}
              <div style={classes.titleText}>{props.data.name}</div>
            </div>
          }
          subheader={
            <div style={classes.subHeader}>
              <div style={classes.envText}>{props.data.source}</div>
            </div>
          }
        />
      </Card>
    </Grid>
  )
}
