import { CenteredDiv, ErrorMessage, SetupProvider } from '@wavetronix/common-components'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import HealthCheckClient from '../api/HealthCheckClient'
import { env } from '../index.js'
import FullPageLoader from './loaders/FullPageLoader'
import MainPage from './MainPage'
import NoLogin from './NoLogin'

const hc = new HealthCheckClient()

function MaybeLogin({ msalInstance }) {
  const { data, error, isLoading } = useQuery({ queryKey: ['loginRequired'], queryFn: async () => await hc.isLoginRequired() })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  if (isLoading || data === undefined) {
    return <FullPageLoader />
  } else if (data) {
    return (
      <SetupProvider env={env} msalInstance={msalInstance} allowedRoles={[]}>
        <MainPage />
      </SetupProvider>
    )
  } else {
    return <NoLogin />
  }
}

export default MaybeLogin
