import { AppBar, Toolbar } from '@mui/material'
import React from 'react'
// import logo from '../resources/wavetronix.svg'
import { WavetronixIcon } from '../resources/GojiraIcons'

const classes = {
  menuLogo: {
    marginRight: 50
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: 'black',
    zIndex: 1300,
    width: '100%'
  }
}

export default function MenuAppBarNoUser(props) {
  const goToHub = () => (window.location.href = 'https://nexus.wtxdev.com')

  return (
    <AppBar position='absolute' style={classes.appBar}>
      <Toolbar>
        <div style={{ flex: 1 }}>
          <WavetronixIcon height={40} style={{ cursor: 'pointer' }} onClick={goToHub} />
        </div>
      </Toolbar>
    </AppBar>
  )
}
