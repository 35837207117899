import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import React from 'react'
import { checkForSuccessInAllEnvs, getEnvIcon, getIcon } from '../utils'

const classes = {
  root: {
    minWidth: 400
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  titleText: {
    fontFamily: 'Klavika',
    fontWeight: 'bold',
    fontSize: 28,
    display: 'inline',
    verticalAlign: 'middle'
  },
  titleIcon: {
    transform: 'scale(0.6)',
    display: 'inline',
    verticalAlign: 'middle'
  },
  envText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 18,
    display: 'inline',
    verticalAlign: 'middle'
  },
  envIcon: {
    transform: 'scale(0.4)',
    display: 'inline',
    verticalAlign: 'middle'
  },
  appType: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 20,
    display: 'inline',
    verticalAlign: 'middle',
    marginRight: '3%'
  },
  desc: {
    fontFamily: 'Proxima Nova',
    fontSize: 24
  },
  pos: {
    marginBottom: 12
  },
  subHeader: {
    marginLeft: '10%'
  },
  statusBox: {
    borderStyle: 'solid',
    borderColor: '#e0e1dd',
    display: 'inline',
    marginTop: 0,
    marginRight: 10,
    marginBottom: 0,
    marginLeft: 0,
    padding: '10px',
    borderWidth: 0.5,
    borderRadius: 10
  }
}

export default function WebStatusCard(props) {
  return (
    <Grid item xs>
      <Card sx={classes.root}>
        <CardHeader
          title={
            <div>
              {getIcon(checkForSuccessInAllEnvs(props.data), classes.titleIcon)}
              <div style={classes.titleText}>{props.data.name}</div>
            </div>
          }
          subheader={
            <div style={classes.subHeader}>
              <div style={classes.statusBox}>
                <div style={classes.envText}>dev</div>
                {getEnvIcon(props.data.dev.status, classes.envIcon)}
              </div>

              <div style={classes.statusBox}>
                <div style={classes.envText}>int</div>
                {getEnvIcon(props.data.int.status, classes.envIcon)}
              </div>

              <div style={classes.statusBox}>
                <div style={classes.envText}>prod</div>
                {getEnvIcon(props.data.prod.status, classes.envIcon)}
              </div>
            </div>
          }
        />
      </Card>
    </Grid>
  )
}
