import { MenuAppBar } from '@wavetronix/common-components'
import React from 'react'
import { env } from '../index.js'
import Dashboard from './Dashboard'

export default function MainPage() {
  return (
    <>
      <MenuAppBar env={env} />
      <Dashboard isLoginRequired={true}></Dashboard>
    </>
  )
}
