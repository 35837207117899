import { Toolbar } from '@mui/material'
import React from 'react'
import { env } from '../index.js'
import Dashboard from './Dashboard'
import MenuAppBarNoUser from './MenuAppBarNoUser'

export default function NoLogin() {
  return (
    <>
      <MenuAppBarNoUser env={env} />
      <Toolbar></Toolbar>
      <Dashboard isLoginRequired={false}></Dashboard>
    </>
  )
}
