import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './index.css'
import MaybeLogin from './Components/MaybeLogin'
import { AppBar } from '@mui/material'
import { CenteredDiv, createWtxAuthInstance, WtxColors } from '@wavetronix/common-components'
import { createRoot } from 'react-dom/client'
import generateEnv from './env/generateEnv.js'
import version from './env/version.json'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const queryClient = new QueryClient()
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <QueryClientProvider client={queryClient}>
      <>
        <MaybeLogin msalInstance={msalInstance} />
        <AppBar
          position='fixed'
          sx={{ top: 'auto', bottom: 0, backgroundColor: 'transparent', color: 'black', boxShadow: 'none' }}>
          <div style={{ float: 'right' }}>{version.version}</div>
        </AppBar>
      </>
    </QueryClientProvider>
  )
}
