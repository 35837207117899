// import goodIcon from './resources/good.svg'
// import badIcon from './resources/bad.svg'
// import runningIcon from './resources/running.svg'
// import unavailableIcon from './resources/unavailable.svg'
import { GoodIcon, BadIcon, RunningIcon, UnavailableIcon } from './resources/GojiraIcons'

export function checkForSuccessInAllEnvs(item) {
  if (item.dev !== undefined && item.dev.status === 'unhealthy') {
    return false
  }
  if (item.int !== undefined && item.int.status === 'unhealthy') {
    return false
  }
  if (item.prod !== undefined && item.prod.status === 'unhealthy') {
    return false
  }
  return true
}

export function checkForOverAllSuccess(statusData) {
  if (statusData == null) {
    return true
  }

  return statusData.webFailures.length === 0 && statusData.buildFailures.length === 0
}

export function getIcon(isHealthy, style) {
  if (isHealthy) return <GoodIcon style={style} />
  return <BadIcon style={style} />
}

export function getBuildIcon(status, style) {
  if (status === 'success') return <GoodIcon style={style} />
  if (status === 'failure') return <BadIcon style={style} />
  if (status === 'running') return <RunningIcon style={style} />
  return <UnavailableIcon style={style} />
}

export function getEnvIcon(status, style) {
  if (status === 'healthy') return <GoodIcon style={style} />
  if (status === 'unavailable') return <UnavailableIcon style={style} />
  return <BadIcon style={style} />
}
