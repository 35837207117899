import { Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { acquireAccessToken, CenteredDiv } from '@wavetronix/common-components'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
// import gojiraLogo from '../resources/gojira_logo_no_text.svg'
import { GojiraIcon } from '../resources/GojiraIcons'
import { checkForOverAllSuccess, getIcon } from '../utils'
import BuildStatusCard from './BuildStatusCard'
import StatusBoard from './StatusBoard'
import WebStatusCard from './WebStatusCard'
import { env } from '../index.js'
import { useMsal } from '@azure/msal-react'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const sectionCount = 3
const checkForChangesIntervalInSeconds = 15
const switchViewIntervalInSeconds = 15

const classes = {
  root: {
    minWidth: 400
  },
  content: {
    backgroundColor: 'transparent',
    marginLeft: '8%',
    marginRight: '8%',
    marginTop: 20
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    marginBottom: 50,
    marginTop: 40
  },
  titleText: {
    fontFamily: 'Klavika',
    fontWeight: 'bold',
    fontSize: 46,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 10,
    marginRight: 10
  },
  timestampText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'normal',
    fontSize: 18
  },
  msgText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 22
  },
  titleIcon: {
    transform: 'scale(2.0)',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  logo: {
    transform: 'scale(1.4)',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  divider: {
    marginLeft: '8%',
    marginRight: '8%'
  },
  sectionTitle: {
    fontFamily: 'Klavika',
    fontWeight: 'normal',
    fontSize: 36,
    color: '#4d4f53',
    marginTop: 20,
    marginLeft: '2%',
    marginRight: '2%'
  },
  selectedSectionTitle: {
    fontFamily: 'Klavika',
    fontWeight: 'bold',

    fontSize: 36,
    marginTop: 20,
    marginLeft: '2%',
    marginRight: '2%'
  }
}

function getConfiguration(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

async function getHealthChecksHash(isLoginRequired, msalInstance, accounts) {
  if (!isLoginRequired) {
    console.log('no login!')
    return await axios.get(`${env.urls.healthCheckURL}/api/v1/healthcheck/internal/change`).then(res => res.data)
  }
  let token = await acquireAccessToken(msalInstance, accounts, env)
  console.log('**** login!')
  return await axios.get(`${env.urls.healthCheckURL}/api/v1/healthcheck/change`, getConfiguration(token)).then(res => res.data)
}

async function getHealthChecks(isLoginRequired, msalInstance, accounts) {
  if (!isLoginRequired) {
    console.log('no login!')
    return await axios.get(`${env.urls.healthCheckURL}/api/v1/healthcheck/internal`).then(res => res.data)
  }
  let token = await acquireAccessToken(msalInstance, accounts, env)
  console.log('**** login!')
  return await axios.get(`${env.urls.healthCheckURL}/api/v1/healthcheck`, getConfiguration(token)).then(res => res.data)
}

function Dashboard({ isLoginRequired }) {
  const { instance, accounts } = useMsal()
  const [section, setSection] = useState(1)

  const [hash, setHash] = useState('')
  const { isLoading, error, data } = useQuery({
    queryKey: ['repoData'],
    queryFn: () => getHealthChecksHash(isLoginRequired, instance, accounts).then(hash => hash),
    refetchInterval: checkForChangesIntervalInSeconds * 1000
  })

  const [statusData, setStatusData] = useState({
    uiStatus: [],
    apiStatus: [],
    buildStatus: [],
    webFailures: [],
    buildFailures: []
  })

  useEffect(() => {
    if (data !== hash) {
      setHash(data)
    }
  }, [data, hash])

  useEffect(() => {
    getHealthChecks(isLoginRequired, instance, accounts).then(s => setStatusData(s))
  }, [hash, isLoginRequired, instance, accounts])

  useEffect(() => {
    const interval = setInterval(() => {
      setSection(s => {
        let newSection = s + 1
        if (newSection > sectionCount) return 1
        else return newSection
      })
    }, switchViewIntervalInSeconds * 1000)
    return () => clearInterval(interval)
  }, [])

  if (isLoading)
    return (
      <CenteredDiv>
        <Typography sx={classes.msgText}>{'Loading...'}</Typography>
      </CenteredDiv>
    )

  if (error)
    return (
      <CenteredDiv>
        <Typography sx={classes.msgText}>{'An error has occurred: ' + error.message}</Typography>
      </CenteredDiv>
    )

  const date = new Date()

  var i = 0
  return (
    <div>
      <CenteredDiv>
        <div style={classes.title}>
          <GojiraIcon style={classes.logo} />
          <Typography sx={classes.titleText}>Gojira Team Systems Status</Typography>
          {getIcon(checkForOverAllSuccess(statusData), classes.titleIcon)}
        </div>
      </CenteredDiv>
      <CenteredDiv>
        <Typography sx={classes.timestampText}>Last Updated on {dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</Typography>
      </CenteredDiv>
      <Divider sx={classes.divider} />

      <StatusBoard isVisible={!checkForOverAllSuccess(statusData)}>
        <CenteredDiv>
          <div style={classes.selectedSectionTitle}>Current Failures</div>
        </CenteredDiv>
        <div style={classes.content}>
          <Grid container spacing={3}>
            {statusData.webFailures
              .map(item => <WebStatusCard data={item} key={i++} />)
              .concat(statusData.buildFailures.map(item => <BuildStatusCard data={item} key={i++} />))}
          </Grid>
        </div>
      </StatusBoard>

      <CenteredDiv>
        <div style={section === 1 ? classes.selectedSectionTitle : classes.sectionTitle}>UI Status</div>
        <div style={section === 2 ? classes.selectedSectionTitle : classes.sectionTitle}>API Status</div>
        <div style={section === 3 ? classes.selectedSectionTitle : classes.sectionTitle}>Build Status</div>
      </CenteredDiv>

      <StatusBoard isVisible={section === 1}>
        <div style={classes.content}>
          <Grid container spacing={3}>
            {statusData.uiStatus.map(item => (
              <WebStatusCard data={item} key={i++} />
            ))}
          </Grid>
        </div>
      </StatusBoard>

      <StatusBoard isVisible={section === 2}>
        <div style={classes.content}>
          <Grid container spacing={3}>
            {statusData.apiStatus.map(item => (
              <WebStatusCard data={item} key={i++} />
            ))}
          </Grid>
        </div>
      </StatusBoard>

      <StatusBoard isVisible={section === 3}>
        <div style={classes.content}>
          <Grid container spacing={3}>
            {statusData.buildStatus.map(item => (
              <BuildStatusCard data={item} key={i++} />
            ))}
          </Grid>
        </div>
      </StatusBoard>
    </div>
  )
}

export default Dashboard
