import axios from 'axios'
import { env } from '../index.js'
/**
 * @class HealthCheckClient
 * @description A JS client to contact Health Check Service.
 */
class HealthCheckClient {
  constructor(timeout = 30) {
    this.timeout = timeout * 1000
    this.isLoginRequired = this.isLoginRequired.bind(this)
  }

  /**
   * Determines if this client requires a user login
   */
  isLoginRequired() {
    return new Promise(async resolve => {
      axios
        .get(`${env.urls.healthCheckURL}/api/v1/healthcheck/internal/check`, {
          timeout: this.timeout
        })
        .then(response => {
          resolve(false)
        })
        .catch(error => {
          resolve(true)
        })
    })
  }
}

export default HealthCheckClient
